.footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 56px;
    font-size: 13px;
    letter-spacing: 0.4px;
    color: var(--white);
    background-color: var(--primary);
  }
  
.footer-link {
    margin-right: 32px;
    color: white;
    text-decoration: none;
}
