.banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 64px;
    color: var(--white);
    background-color: var(--primary);
}

.inner-container {
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
    padding-left: 32px;
}

.company {
    display: flex;
    justify-content: center;
    align-self: center;

    p {
        font-size: 18px;
        font-weight: 100;
        margin-left: 15px;
    }
}

.logged-in-company {
    display: flex;
    align-self: center;
    // flex-grow: 1;

    p {
        font-size: 18px;
        font-weight: 100;
        margin-left: 15px;
    }
}
