div{
    font: var(--primaryFont);
}



.processFile, .downloadButton, .similartrialsDownloadButton, .DownloadButton{
    background-color : var(--primary);
    color:var(--lightGrayishBlue3);
    padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin:5px;
  border-radius: 5px;
  font: var(--primaryFont);
}


.inputfile{
    border: 5px double var(--secondary);
    color:var(--veryDarkBlue);
    padding: 2px;
    border-radius: 10px;
    font:20px;
    margin :10px 5px;
}

.inputfile::-webkit-file-upload-button{
    background-color: var(--primary);
    color:var(--lightGrayishBlue3);
    padding: 8px 16px;
    border: none;
border-radius: 10px;
cursor:pointer;
}

.processFile:disabled, .inputfile:disabled{
    opacity: 0.5;
    filter: alpha(opacity=50);
}


table{
    width: 90%; 
  border-collapse: collapse;
  margin: 20px;
  margin-left: auto;
  margin-right: auto;
}
table, th, td {
    
    border: 1px solid var(--primary);
    
}

th, td {
    text-align: left;

  font: var(--primaryFont);
    
  }
th{
    background-color: var(--primary);
    color:var(--lightGrayishBlue3);
    text-align: center;
}


.tableDic{
    overflow-x:auto;
}