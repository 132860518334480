:root {
  --primary: #374c72;
  --secondary: #217DA2;
  --white: #ffffff;
  --lightGrayishBlue: #F5F6F7;
  --lightGrayishBlue2: #E6E7E8;
  --lightGrayishBlue3: #EDF5F8;
  --veryDarkBlue: #223250;
  --darkGrayishBlue: #939598;
  --strongRed: #C13826;
  --normalBlue: #238FB5;
  --veryPaleRed: #FFEFED;
  --backgroundGray: #2232507C;
  --lightBlue: #217DA215;
  --primaryFont: "Titillium Web";
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
